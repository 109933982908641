import { readFile, writeFile } from '../utils/fs-helpers'

import {
  getEntity,
  getFooterData,
  getHeaderData,
  getPageData,
  getPreviewByID,
} from '@api/index'
import { generateSlug } from '@api/utils'

import { default as normalizeBreadcrumb } from '@models/Breadcrumb'
import { default as normalizeFooterData } from '@models/Footer'
import { default as normalizeHeaderData } from '@models/Header'
import normalizeMetadata from '@models/Metadata'

import Breadcrumb from '@/molecules/Breadcrumb'
import MyQuoteBar from '@/molecules/MyQuoteBar'
import PreviewBadge from '@/molecules/PreviewBadge'
import { SectionBuilder } from '@/organisms'

import Layout from '@/organisms/Layout'
// import { newsletterScript } from '@utils/scripts/newsletter-script'
// import Script from 'next/script'

export const getStaticPaths = async () => {
  const entitiesToGet = [
    {
      entity: ['pages'],
    },
  ]

  const entities = entitiesToGet.reduce((acc, curr) => {
    curr.entity.forEach((item) => {
      acc.push({
        entity: item,
      })
    })

    return acc
  }, [])

  const pages = await Promise.all(
    entities.map(({ entity }) => getEntity({ entity }))
  )

  const paths = pages.reduce((acc, { data }) => {
    if (!data) return acc

    const pathData = Array.isArray(data) ? data : [data]
    const paths = pathData.map(({ slug, isHomepage }) => {
      return {
        params: {
          slug: generateSlug(slug, isHomepage),
        },
      }
    })

    return [...acc, ...paths]
  }, [])

  try {
    const pagesCache = pages.reduce((acc, { data, entity }) => {
      if (!data || data.length === 0) return acc

      const pathData = Array.isArray(data) ? data : [data]
      const paths = pathData.map(({ id, slug, isHomepage }) => {
        return {
          id,
          slug: generateSlug(slug),
          entity,
          isHomepage,
        }
      })

      return [...acc, ...paths]
    }, [])

    await writeFile(
      'cache/static-paths.json',
      JSON.stringify([...pagesCache]),
      'utf-8'
    )
  } catch (error) {
    console.error('Generate cache file error', error)
  }

  return {
    paths: paths,
    fallback: false,
  }
}

export const getStaticProps = async ({ params, draftMode }) => {
  const getData = async (paramSlug) => {
    const cacheFile = await readFile('cache/static-paths.json', 'utf-8')
    const staticPaths = JSON.parse(cacheFile)

    const staticPathData = !paramSlug
      ? staticPaths.find((path) => path.isHomepage)
      : staticPaths.find((path) => {
          const staticSlug = Array.isArray(path.slug)
            ? path.slug.join('/')
            : path.slug
          const paramSlugNormalized = Array.isArray(paramSlug)
            ? paramSlug.join('/')
            : paramSlug
          return staticSlug === paramSlugNormalized
        })

    if (staticPathData) {
      const { slug, entity, isHomepage } = staticPathData

      const [data] = await Promise.all([
        getPageData({
          entity,
          slug: `${slug.join('/')}`,
        }),
      ])

      return {
        data: Array.isArray(data.data) ? data.data[0] : data.data,
        isHomepage,
      }
    }
  }

  const data = !draftMode
    ? await getData(params.slug)
    : await getPreviewByID(params.slug, 'previewPages')

  const { pageData, isHomepage } =
    !draftMode && data
      ? { pageData: data.data, isHomepage: data.isHomepage }
      : { pageData: data, isHomepage: false }

  if (!pageData) {
    return {
      notFound: true,
    }
  }

  const [footer, header] = await Promise.all([getFooterData(), getHeaderData()])
  const metadata = normalizeMetadata(pageData.seo)

  return {
    props: {
      isPreview: !!draftMode,
      metadata,
      breadcrumb: !isHomepage
        ? normalizeBreadcrumb(pageData.breadcrumbs)
        : null,
      sections: pageData.sections,
      footer: normalizeFooterData(footer),
      header: normalizeHeaderData(header),
      showStickyMyQuote: pageData?.showStickyMyQuote ? true : false,
      isHomepage,
    },
  }
}

export default function Page({
  isPreview,
  metadata,
  breadcrumb,
  header,
  footer,
  sections,
  showStickyMyQuote,
  nonce,
  // isHomepage = false,
}) {
  return (
    <Layout
      metadata={metadata}
      headerData={header}
      footerData={footer}
      nonce={nonce}
    >
      {isPreview ? <PreviewBadge /> : null}
      {breadcrumb ? <Breadcrumb items={breadcrumb} /> : null}
      <SectionBuilder data={sections} />
      {showStickyMyQuote ? <MyQuoteBar /> : null}
      {/* {isHomepage ? (
        <Script
          id={'news-script-home'}
          strategy={'afterInteractive'}
          nonce={`nonce-${nonce}`}
        >
          {newsletterScript}
        </Script>
      ) : null} */}
    </Layout>
  )
}

// http://localhost:3000/api/preview?type=page&id=33&secret=YCbRb6W3uxrjgIk93H36LGE5US4XLYHgYoIh9Px1fSNsvs4bCxTcotpF0SPRzq3R
